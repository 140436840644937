// 病害调查记录
const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [{
                prop: 'BHMC',
                label: '病害名称',
                minWidth: 160,
            }, {
                prop: 'YCZC',
                label: '遗产组成'
            }, {
                prop: 'YCYSMC',
                label: '遗产要素'
            }, {
                prop: 'BHBH',
                label: '病害编号'
            }, {
                prop: 'BHLX_BM',
                label: '病害类型'
            }, {
                prop: 'BHWZ',
                label: '病害位置',
            }, {
                prop: 'BHSYT',
                label: '病害示意图',
                minWidth: 105,
                showImg: true,
            }],
        },
    },
    // snSearch: {
    //     selectData: [{
    //         label: '病害类型',
    //         clearable: true,
    //         placeholder: "请选择病害类型",
    //         list: [{
    //             ID: '1',
    //             NAME: '排水不畅'
    //         }, {
    //             ID: '2',
    //             NAME: '位移'
    //         }, {
    //             ID: '3',
    //             NAME: '不均匀沉降'
    //         }],
    //         optionLabel: 'NAME',
    //         optionValue: 'ID',
    //         value: 'BHLX',
    //         operateType: 'search',
    //         isShow: true
    //     }]
    // }
};
export default selfData;